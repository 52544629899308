<template>
	<div class="container">
		<div class="card shadow" :class='colorScheme.card'>
			<div class='card-header'>
				<h1 class="h3 mb-0">Odd jobs</h1>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'HomeUser',
}
</script>
